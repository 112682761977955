<template>
  <div class="" id="activity"  style="overflow-y: auto">
    <div class="sticky-top" style="z-index: 100; background-color: #1D2021; height: 75px;">
      <div class="container pt-3 pb-1 ">

        <div class="d-flex justify-content-between mb-3">
          <!-- Type -->
          <div class="dropdown text-white pt-2 activity-dropdown disable-text-select" style="cursor: pointer;">
            <a class="activity-dropdown-toggle" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="font-weight-light mr-2"> {{niceTransactionFilterName(filterTransactions)}}</span> <div class="activity-dropodwn-icon"><font-awesome-icon icon="caret-down" /></div>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a v-if="filterTransactions != 'All Events'" class="dropdown-item text-white-hover"  @click="filterTransactions = 'All Events'">All Events</a>
              <a v-if="filterTransactions != 'Deposits'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Deposits'">Deposits</a>
              <a v-if="filterTransactions != 'Withdrawals'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Withdrawals'">Withdrawals</a>
              <a v-if="filterTransactions != 'Purchases'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Purchases'">Purchases</a>
              <a v-if="filterTransactions != 'Listings'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Listings'">Listings</a>
              <a v-if="filterTransactions != 'Sales'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Sales'">Sales</a>
              <a v-if="filterTransactions != 'Payment Processor'" class="dropdown-item text-white-hover" @click="filterTransactions = 'Payment Processor'">Payment Processor</a>
              <a v-if="filterTransactions != 'USER_LOGIN'" class="dropdown-item text-white-hover" @click="filterTransactions = 'USER_LOGIN'">Login History</a>
            </div>
          </div>

          <!-- Game -->
          <div class="dropdown text-white pt-2 activity-dropdown disable-text-select" style="cursor: pointer;">
            <a class="activity-dropdown-toggle" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="font-weight-light mr-2"> {{filterGames}}</span> <div class="activity-dropodwn-icon"><font-awesome-icon icon="caret-down" /></div>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item text-white-hover" v-if="filterGames != 'All Games'" @click="filterGames = 'All Games'">All Games</a>
              <a class="dropdown-item text-white-hover" v-if="filterGames != 'CS:GO'" @click="filterGames = 'CS:GO'">CS:GO</a>
              <a class="dropdown-item text-white-hover" v-if="filterGames != 'Dota 2'" @click="filterGames = 'Dota 2'">Dota 2</a>
              <a class="dropdown-item text-white-hover" v-if="filterGames != 'TF2'" @click="filterGames = 'TF2'">TF2</a>
              <a class="dropdown-item text-white-hover" v-if="filterGames != 'Rust'" @click="filterGames = 'Rust'">Rust</a>
            </div>
          </div>

          <!-- Past 30 days -->
          <div class="dropdown text-white pt-2 activity-dropdown disable-text-select" style="cursor: pointer;">
            <a class="activity-dropdown-toggle" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="font-weight-light mr-2"> {{parseFilterDateString()}}</span> <div class="activity-dropodwn-icon"><font-awesome-icon icon="caret-down" /></div>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item text-white-hover" v-if="filterDate != '30 Days'" @click="filterDate = '30 Days'">30 Days</a>
              <a class="dropdown-item text-white-hover" v-if="filterDate != '60 Days'" @click="filterDate = '60 Days'">60 Days</a>
              <a class="dropdown-item text-white-hover" v-if="filterDate != '90 Days'" @click="filterDate = '90 Days'">90 Days</a>
              <a class="dropdown-item text-white-hover" v-if="filterDate != 'This Year'" @click="filterDate = 'This Year'">This Year</a>
              <a class="dropdown-item text-white-hover" v-if="filterDate != 'Last Year'" @click="filterDate = 'Last Year'">Last Year</a>
              <a class="dropdown-item text-white-hover" v-if="filterDate != 'All Time'" @click="filterDate = 'All Time'">All Time</a>
            </div>
          </div>

          <div>
            <div class="activity-textbox input-group">
              <input class="form-control " type="text" placeholder="Search for transactions..." style="width: 250px; background: #E6E6E6; font-style: italic;" v-model="searchQuery">
              <span class="item-search-icon" style="padding-left: 200px;">
                  <font-awesome-icon icon="search" />
              </span>
              <div></div>
            </div>
          </div>

          <!-- Past 30 days -->
          <div class="dropdown text-white pt-2">
            <a href="#0" class="text-white font-weight-light mr-2" @click="downloadActivities()"> <u>Download</u></a> 
          </div>
        </div>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="mt-5">
          <div class="loader"></div>
      </div>
    </template>
    <div v-else class="container mt-5" style="" >
      <div style="overflow-y: hidden; border-top-right-radius: 10px; border-top-left-radius: 10px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
        <table v-if="activities.length > 0" class="table guard-font table-fixed table-striped h-100" style="border-top-right-radius: 10px; border-top-left-radius: 10px; margin-bottom: 0 !important;">
          <thead>
            <tr style="background-color: #EAEAEA; border-top-left-radius: 10px; border-top: 1px; border-top-right-radius:10px;">
              <!-- <th>i</th> -->
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'date-desc' ? 'date-desc' : 'date-asc'">Date</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'date-desc' ? 'date-desc' : 'date-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='date-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='date-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'type-desc' ? 'type-desc' : 'type-asc'">Type</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'type-desc' ? 'type-desc' : 'type-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='type-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='type-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'itemName-desc' ? 'itemName-desc' : 'itemName-asc'">Name </span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'itemName-desc' ? 'itemName-desc' : 'itemName-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='itemName-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='itemName-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'status-desc' ? 'status-desc' : 'status-asc'">Status</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'status-desc' ? 'status-desc' : 'status-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='status-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='status-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'gross-desc' ? 'gross-desc' : 'gross-asc'">Gross</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'gross-desc' ? 'gross-desc' : 'gross-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='gross-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='gross-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
                </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'net-desc' ? 'net-desc' : 'net-asc'">Net</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'net-desc' ? 'net-desc' : 'net-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='net-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='net-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
              <th scope="col" class="disable-text-select">
                <span style="font-weight: 600; font-style: normal; font-family: Roboto; cursor: pointer;" @click="sortOrder = sortOrder != 'balance-desc' ? 'balance-desc' : 'balance-asc'">Balance</span>
                <font-awesome-layers class="fa-lg" @click="sortOrder = sortOrder != 'balance-desc' ? 'balance-desc' : 'balance-asc'">
                  <font-awesome-icon icon="caret-down" style="padding-top: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='balance-desc' ? '#ED6B33' : '#B0B0B0'}"/>
                  <font-awesome-icon icon="caret-up" style="padding-bottom: 7px; cursor: pointer;" size="lg" :style="{color: sortOrder=='balance-asc' ? '#ED6B33' : '#B0B0B0'}"/>
                </font-awesome-layers>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(activity) in activities">
              <tr v-bind:key="activity.id" style="cursor: pointer;" @click="toggle(activity)" :class="{ opened: opened.includes(activity.id) }"> 
                <!-- <td>{{i}}</td> -->
                <td scope="row" style="vertical-align: middle;">{{activity.date}}</td>
                <td style="vertical-align: middle;">{{activity.type}}</td>
                <td >
                  <div class="d-flex " v-if="activity.item">
                    <div class="activity-image"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + activity.item.image + '/256fx256f'" alt=""></div>
                    <div class="ib item-name my-auto ml-2">
                      <div>
                        <div class="item-title">{{activity.item.name}}</div>
                        <div class="item-status">
                            <div v-if="activity.item.stattrak" class="item-stattrak">StatTrak™</div> 
                            <span v-if="activity.item.stattrak && activity.item.wear"> | </span>
                            <div v-if="activity.item.wear" class="item-wear">{{activity.item.wear}} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="activity.transaction">
                    <td scope="row" style="vertical-align: middle; padding:0; height:100%; border: 0;">
                      <div class="d-flex">
                        <div class="logo" style="width:70px;">
                          <img style="max-height: 50px; max-width: 50px" :src="getDepositWithdrawalLogo(activity.transaction.method)" alt="">
                        </div>
                        <div class="my-auto">{{mapTransactionMethod(activity.transaction.method)}}</div>
                      </div>
                    </td>
                  </div>
                  <div v-else-if="activity.type == 'Login'">
                    <td scope="row" style="vertical-align: middle; padding:0; height:100%; border: 0;">
                      <div class="d-flex">
                        <div class="my-auto">{{ activity.additionalInformation && activity.additionalInformation.ipInfo ? (activity.additionalInformation.ipInfo.region_name + ", " + activity.additionalInformation.ipInfo.city + ", " + activity.additionalInformation.ipInfo.region_code + " (" + activity.additionalInformation.ipInfo.country_code + ")") : "Unknown" }}</div>
                      </div>
                    </td>
                  </div>
                  <div style="vertical-align: middle; padding:0; height:100%" v-else>Unknown Name</div>
                </td>
                <td style="vertical-align: middle;">{{formatActivityStatus(activity)}}</td>
                <td style="vertical-align: middle;">{{activity.gross != 0 ? numeral(activity.gross/100).format('$0,0.00') : "-"}}</td>
                <td style="vertical-align: middle;">{{activity.net != 0 ? numeral(activity.net/100).format('$0,0.00') : "-"}}</td>
                <td style="vertical-align: middle;">{{activity.balance != null ? numeral(activity.balance/100).format('$0,0.00') : "-"}}</td>
              </tr>

              <tr v-bind:key="'block-' + activity.id" v-if="opened.includes(activity.id)">
                <td colspan="8" style="padding: 0; border:0;">
                  <div colspan=8>
                    <table class="table guard-font table-fixed" style="border-top-right-radius: 15px; border-top-left-radius: 15px; background-color: #EEEEEE; margin: 0; border-bottom: 1px solid black;">
                      <thead>
                        <tr style="background-color: #EEEEEE; border:0;" v-if="activity.item">
                          <th scope="col">Item:</th>
                          <th scope="col">Game: {{getGame(activity.item.appId)}}</th>
                          <th scope="col">Listing History:</th>
                          <th scope="col" style="text-align: right; cursor: pointer; width: 80px; color:#ED6B33;" @click="toggle(activity)">- Collapse</th>
                        </tr>

                        <tr style="background-color: #EEEEEE; border:0;" v-if="!activity.item">
                          <th scope="col">
                            <div class="d-flex">
                              <div class="my-auto mr-3">Transaction ID:</div>
                              <div class="d-flex justify-content-center "><div class="copyme" @click="copyToClipboard(activity.transaction.id)" >{{activity.transaction.id}}</div><router-link :to="'/success/' + activity.transaction.id" v-slot="{ href, navigate }"><a :href="href" @click="navigate" class="my-auto ml-3">View / Download Receipt</a></router-link></div>
                            </div>
                          </th>
                          
                          <th scope="col" style="text-align: right; cursor: pointer; width: 80px; color:#ED6B33;" @click="toggle(activity)">- Collapse</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-if="activity.item"> <img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + activity.item.image + '/256fx256f'" alt="" style="height: 100px;margin-left:20px"> </td>
                          <td v-if="activity.item" >
                            <div class="d-flex justify-content-center flex-column" style="height:100px">
                              <div class="d-flex">
                                <div>
                                  <div style="font-size: 20px; color: #545454; height: 30px">{{ activity.item.name }}</div>
                                  <div class="d-flex">
                                    <div style="color: #C46C4F; font-size: 14px;font-weight: 500;" v-if="activity.item.stattrak">StatTrak™ </div>
                                    <div style="color: #7BAA64; font-size: 14px;font-weight: 500;" v-if="activity.item.wear"> {{activity.item.wear}}</div>
                                  </div>
                                </div>
                                <div style="margin-left: 40px;">
                                  <div v-if="activity.item.stickers" class="item-stickers" style="margin-bottom: 6px;">
                                    <div v-for="(sticker, i) of activity.item.stickers" v-bind:key="i" class="item-sticker">
                                      <img :src="sticker.image"
                                      style="max-width: 50px"
                                      v-tooltip="sticker.name + parseStickerScrape(sticker.wear)"
                                      class="sticker-tint"
                                      :class="activity.item.appId === 440 ? getTf2BackdropClass(sticker.name) : ''"
                                      />
                                      <!-- :style="{ 'outline': '11px solid rgba(255, 0, 0, '+(10-sticker.wear)/10+') !important' }" -->
                                    </div>
                                  </div>
                                  <div v-if="activity.item.float" style="font-weight: 500;font-size: 12px;color: #484848;">Float: <span style="font-weight: normal;font-size: 12px;color: #484848;">{{(parseFloat(activity.item.float).toFixed(5) + '').padEnd(5, 'z').replace(/z/gmi, '')}}</span></div>
                                  <div v-if="activity.item.patternIndex" style="font-weight: 500;font-size: 12px;color: #484848;">Pattern Index: <span style="font-weight: normal;font-size: 12px;color: #484848;">{{activity.item.patternIndex}}</span></div>
                                </div>
                              </div>
                              
                            </div>
                          </td>
                          <td v-if="activity.item">
                            <div>
                              <ul v-if="activity.listingHistory" class="custom-ul" style="height:100px; overflow-y: auto; overflow-x: hidden;">
                                <li v-for="entry of activity.listingHistory" v-bind:key="entry.date">
                                  <div class="row">
                                    <span class="col" style="font-weight: 500; font-family: Roboto; padding: 0;">{{entry.event}}</span>
                                    <span class="col-7" style="font-weight: 500; font-family: Roboto; padding: 0;">{{entry.date}} @ {{entry.time}}</span>
                                  </div>
                                </li>
                              </ul>
                              <img v-else src="@/assets/loading_spinner_animated.svg" class="my-auto" style="height: 80px;"/>
                            </div>
                          </td>
                          <td v-if="activity.item"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div v-else class="my-1 mx-2">
          <span style="font-weight: 500;">No Activity info found with these current filters! Try out the site a bit longer and come back to this page to see detailed information on your actions or <a href="#0" style="color: orange;" @click="resetFilters()">reset your filters.</a></span>
        </div>
      </div>
    </div>
    <br>
    <div>&nbsp;</div>
  </div>
</template>

<script>
import api from "../api";
import numeral from 'numeral';
import Moment from "moment";
const moment = extendMoment(Moment);
import { extendMoment } from "moment-range";
var momentTimezone = require('moment-timezone');
const ObjectsToCsv = require('objects-to-csv');
const stringSimilarity = require("string-similarity");
import parseItems from "../utils/parseItems";

import paypalLogo           from '../assets/paypallogo.png'
import coinbaseLogo         from '../assets/coinbase.png'
import cardLogo             from '../assets/card.png'
import g2aLogo              from '../assets/g2apay.png'
import appleLogo            from '../assets/applepay.png'
import gpayLogo             from '../assets/gpay.png'
import GoCoinLogo           from '../assets/GoCoin.svg'
import paysafecardLogo      from '../assets/paysafecard.png'

export default {
  data() {
    return {
      activities: [],
      opened: [],
      sortOrder: "date-desc",
      filterTransactions: "All Events",
      filterGames: "All Games",
      filterDate: "30 Days",
      searchQuery: "",
      isLoading: false,
    }
  },
  computed: {
  },
  methods: {
    niceTransactionFilterName(transactionFilter) {
      if (transactionFilter == "USER_LOGIN") return "User Logins" 
      return transactionFilter;
    },
    copyToClipboard(text) {
      let self = this;
      this.$copyText(text).then(function (e) {
        //console.log(e)
        self.$toasted.show('Copied!',{
          duration: 1000,
          position: 'bottom-left'
        });
      }, function (e) {
        console.error('Failed to copy to clipboard.');
      })
    },
    mapTransactionMethod(method) {
      if (method == "Stripe") return "Debit / Credit card";
      if (method == "PayPal") return "PayPal";
      if (method == "GoCoin") return "Crypto";
      if (method == "Coinbase") return "Crypto";
      return method;
    },
    getDepositWithdrawalLogo(method) {
      if (method == "Stripe") return cardLogo;
      if (method == "PayPal") return paypalLogo;
      if (method == "GoCoin") return GoCoinLogo;
      if (method == "Coinbase") return coinbaseLogo;

      console.log("No logo for " + method);
      return "https://placehold.it/60x60"
    },
    async getActivities(){ // Using property-stored filters
      this.isLoading = true;
      let params = {
        userId: this.$store.getters["auth/id"],
        transactions: this.filterTransactions,
        timeRange: this.filterDate,
        game: this.filterGames,
      }
      if (this.searchQuery) {params.searchQuery = this.searchQuery};

      try {
        let activities = (await api.getActivities(params)).data
        console.log("Got activities");
        this.activities = await this.formatActivities(activities)
        console.log("Got processed activities");
        this.isLoading = false;
      } catch (e) {
        console.error(e.stack);
      }
    },
    async downloadActivities(){
      let cleanedActivities = []
      for (let activity of this.activities){
        cleanedActivities.push({
          date: activity.date,
          type: activity.type,
          item: activity.item ? activity.item.name : "-",
          status: activity.status,
          gross: activity.gross != 0 ? numeral(activity.gross/100).format('$0,0.00') : "-",
          fee: activity.fee != 0 ? numeral(activity.fee/100).format('$0,0.00') : "-",
          net: activity.net != 0 ? numeral(activity.net/100).format('$0,0.00') : "-",
          balance: activity.balance != 0 ? numeral(activity.balance/100).format('$0,0.00') : "-"
        })
      }
      let csv = new ObjectsToCsv(cleanedActivities);
      let csvContent = "data:text/csv;charset=utf-8," + (await csv.toString())
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "activity.csv");
      document.body.appendChild(link);

      link.click();
    },
    parseFilterDateString(){
      if (this.filterDate == "This Year" || this.filterDate == "Last Year" || this.filterDate == "All Time"){
        return this.filterDate;
      }
      else return "Past " + this.filterDate 
    },
    resetFilters(){
      this.filterTransactions = "All Events",
      this.filterGames = "All Games",
      this.filterDate = "30 Days"
    },
    async formatActivities(activities){
      let taxPercent = 0.05;

      for (let activity of activities){
        activity.date = this.formatActivityDate(activity.createdAt);
        activity.createdAt = activity.createdAt;

        if (activity.item){
          // Not sure if parsing should be here.
          activity.item = (await parseItems([activity.item]))[0] 
          activity.status = activity.item.state
        }

        // Might need to create a task for collating similar logs together or something of the sort
        activity.type = this.formatActivityType(activity.type)
      }

      // prettyfy
      return activities;
    },
    sortActivitiesByDate(activities, order){
      // TODO: Passing in "desc" doesn't really do anything currently - might possibly be bad design, need to re-consider
      activities.sort(function(a,b){
        a = moment(a.createdAt).format()
        b = moment(b.createdAt).format()
        if (moment(a).isAfter(moment(b))){
          return -1;
        }
        else if (moment(b).isAfter(moment(a))){
          return 1;
        }
        return 0;
      })
      // Returning in ascending order
      if (order == "asc"){
        activities = activities.reverse();
      }
      return activities;
    },
    parseStickerScrape(wear){
      if(wear === 10 || wear === undefined){
        return ""
      }
      return " (" + wear*10 + "%)"
    },
    getTf2BackdropClass(name){
      if (name.split(' ')[1] == "Unusual"){
        return "tf2-unusual-backdrop"
      }

      return "tf2-part-backdrop"
    },
    toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    formatActivityStatus(activity) {
      activity.status = "Success"; // Setting a default here
      if (activity.type == "Delisted") return 'Success';  // ? https://app.clickup.com/t/1cdeg3r
      if (activity.type == "Listing") return 'Listed';  // ? https://app.clickup.com/t/1cdeg3r
      if (activity.type == "Item Withdraw Successful") return "Success"
      if (activity.type == "Item Withdraw Requested") return "Pending"

      return this.toTitleCase(status);
    },
    formatActivityType(type){
      switch(type){
        case "LISTING_PRICECHANGE":
          type = "Price change";
          break;
        case "LISTING":
          type = "Listing"
          break
        case "UNLISTING":
          type = "Delisted"
          break
        case "DEPOSITING_ITEM":
          type = "Item Deposit"
          break
        case "WITHDRAW_REQUESTED":
          type = "Item Withdraw Requested"
          break
        case "WITHDRAWN_ITEM":
          type = "Item Withdraw Successful"
          break
        case "DEPOSITING_FUNDS":
          type = "Deposit Funds"
          break
        case "WITHDRAWING_FUNDS":
          type = "Withdrew Funds"
          break
        case "ITEM_STATE_CHANGE":
          type = "Changed Item State" // TODO: Should probably rename this (not even sure if this is needed)
          break
        case "PROFILE_UPDATE":
          type = "Profile Update"
          break
        case "WATCHLIST_UPDATE":
          type = "Updated Watchlist"
          break
        case "USER_BANNED":
          type = "User Banned" // Assuming this is the own user getting banned, not the admin banning someone else
          break
        case "USER_LOGIN":
          type = "Login"
          break
        case "PAYMENT_FEEDBACK":
          type = "Payment Processor"
          break
        case "PURCHASING_ITEMS":
          type = "Item Purchase"
          break
        case "SELLING_ITEMS":
          type = "Item Sale"
          break
        case "TRADEOFFER_SENT":
          type = "Tradeoffer Sent"
          break
        case "ERRORED":
          type = "Error"
          break
        case "UNKNOWN":
          type = "Unknown"
          break
      }
      return type;
    },
    capitalizeFirstLetter(text){
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    },
    formatActivityDate(date){
      let locale = moment.tz.guess()
      date = momentTimezone(date).tz(locale).format("MMM DD, YYYY h:mm A zz")
      return date
    },
    numeral,
    async toggle(activity) {
      if (!activity.item && !activity.transaction) return console.log("Not openable"); // TODO: At some point TX too.
      
      const index = this.opened.indexOf(activity.id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(activity.id)
      }
      
      if (activity.item) {
        // Getting listing history for the item
        if (index == -1) {
          let allActivities = (await api.getActivities({
            userId: this.$store.getters["auth/id"],
            item: activity.item.id
          })).data;
          // Aggregating listing history
          
          activity.listingHistory = allActivities.map(x => {
            let event = x.type;

            if (x.type == "LISTING") {
              event = `Listed at ${numeral(activity.item.listPrice).format('$0,0.00')}`
            } else if (x.type == "UNLISTING") {
              event = "Unlisted"
            }

            return {
              event: this.formatActivityType(event),
              date: momentTimezone(x.createdAt).tz(moment.tz.guess()).format("MMM DD, YYYY"),
              time: momentTimezone(x.createdAt).tz(moment.tz.guess()).format("HH:mmA"),
            }
          });

          for (let hist of activity.listingHistory) {
            if (hist.event == 'PURCHASING_ITEMS') {
              activity.listingHistory.splice(activity.listingHistory.indexOf(hist), 0, { event: "SITE_INVENTORY", date: hist.date, time: hist.time })
              break;
            }
          }
        }


        if (activity.transaction) {
          // TODO: Something?
        }
        console.log("Loaded activity history");
        this.$forceUpdate();
      }
    },
    getGame(appId) {
      if (appId == '730') 
        return "Counter-Strike: Global Offensive"
      if (appId == '440')
        return 'TF2'
      if (appId == '570')
        return 'Dota 2'
      if (appId == '252490')
        return 'Rust'
      return "Unknown"
    }
  },
  async mounted(){
    console.log("Hello!")
    if (this.$route.query.filter) {
      this.filterTransactions = this.$route.query.filter;
    }
    await this.getActivities()
  },
  watch: {
    sortOrder(){
      // Splitting first into field to sort on
      // let field = this.sortOrder.split("-")[0];
      // console.log("field: ", field)
      switch(this.sortOrder){
        case "date-desc":
          this.sortActivitiesByDate(this.activities, "desc")
          break
        case "date-asc":
          this.sortActivitiesByDate(this.activities, "asc")
          break
        case "type-desc":
          this.activities.sort((a,b) => (a.type < b.type) ? 1 : ((b.type < a.type) ? -1 : 0))
          break
        case "type-asc":
          this.activities.sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))
          break
        case "itemName-desc":
          this.activities.sort(function (a,b) {
            // Since not all activities have an item, we need to have item-less activities at the end
            if (!a.item && b.item) return 1
            if (a.item && !b.item) return -1
            if (!a.item && !b.item) return 0
            if (a.item.name > b.item.name) return 1
            if (a.item.name < b.item.name) return -1
            if (a.item.name == b.item.name) return 0
          })
          break
        case "itemName-asc":
          this.activities.sort(function (a,b) {
            // Since not all activities have an item, we need to have item-less activities at the end
            if (!a.item && b.item) return 1
            if (a.item && !b.item) return -1
            if (!a.item && !b.item) return 0
            if (a.item.name > b.item.name) return -1
            if (a.item.name < b.item.name) return 1
            if (a.item.name == b.item.name) return 0
          })
          break
        case "status-desc":
          this.activities.sort((a,b) => (a.status < b.status) ? 1 : ((b.status < a.status) ? -1 : 0))
          break
        case "status-asc":
          this.activities.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0)) 
          break
        case "gross-desc":
          this.activities.sort(function (a,b) {
            // Again we want to keep items without a "gross" value at the bottom
            if (!a.gross && b.gross) return 1
            if (a.gross && !b.gross) return -1
            if (!a.gross && !b.gross) return 0
            if (a.gross > b.gross) return -1
            if (a.gross < b.gross) return 1
            if (a.gross == b.gross) return 0
          })
          break
        case "gross-asc":
          this.activities.sort(function (a,b) {
            if (!a.gross && b.gross) return 1
            if (a.gross && !b.gross) return -1
            if (!a.gross && !b.gross) return 0
            if (a.gross > b.gross) return 1
            if (a.gross < b.gross) return -1
            if (a.gross == b.gross) return 0
          })
          break
        case "fee-desc":
          this.activities.sort(function (a,b) {
            if (!a.fee && b.fee) return 1
            if (a.fee && !b.fee) return -1
            if (!a.fee && !b.fee) return 0
            if (a.fee > b.fee) return -1
            if (a.fee < b.fee) return 1
            if (a.fee == b.fee) return 0
          })
          break
        case "fee-asc":
          this.activities.sort(function (a,b) {
            if (!a.fee && b.fee) return 1
            if (a.fee && !b.fee) return -1
            if (!a.fee && !b.fee) return 0
            if (a.fee > b.fee) return 1
            if (a.fee < b.fee) return -1
            if (a.fee == b.fee) return 0
          })
          break
        case "net-desc":
          this.activities.sort(function (a,b) {
            if (!a.net && b.net) return 1
            if (a.net && !b.net) return -1
            if (!a.net && !b.net) return 0
            if (a.net > b.net) return -1
            if (a.net < b.net) return 1
            if (a.net == b.net) return 0
          })
          break
        case "net-asc":
          this.activities.sort(function (a,b) {
            if (!a.net && b.net) return 1
            if (a.net && !b.net) return -1
            if (!a.net && !b.net) return 0
            if (a.net > b.net) return 1
            if (a.net < b.net) return -1
            if (a.net == b.net) return 0
          })
          break
        case "balance-desc":
          this.activities.sort(function (a,b) {
            if (!a.balance && b.balance) return 1
            if (a.balance && !b.balance) return -1
            if (!a.balance && !b.balance) return 0
            if (a.balance > b.balance) return -1
            if (a.balance < b.balance) return 1
            if (a.balance == b.balance) return 0
          })
          break
        case "balance-asc":
          this.activities.sort(function (a,b) {
            if (!a.balance && b.balance) return 1
            if (a.balance && !b.balance) return -1
            if (!a.balance && !b.balance) return 0
            if (a.balance > b.balance) return 1
            if (a.balance < b.balance) return -1
            if (a.balance == b.balance) return 0
          })
          break
      }
    },
    async filterTransactions(){
      await this.getActivities();
    },
    async filterGames(){
      await this.getActivities();
    },
    async filterDate(){
      await this.getActivities();
    },
    async searchQuery(){
      await this.getActivities();
    }
  }
}
</script>

<style lang="scss">
  .opened {
    background-color: #E5E5E5 !important;
  }
  .guard-font {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
  .activity-image img {
    max-height:40px;
    max-width: 100px;
    height: 40px;
  }

  .activity-textbox {
    >input {
      border-radius: 10px !important;
    }
  }

  .activity-dropdown {

    min-width: 250px;
    width: 250px;
    >.activity-dropdown-toggle{
      background-color: #E6E6E6;
      font-family: Roboto;
      font-style: italic;
      font-size: 16px;

      padding: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-top: -7px;
      width: 100%;
      display: block;
      border-radius: 10px;
      >.activity-dropodwn-icon {
        float: right;
        display: inline;
      }
      >* {
        padding-left: 5px;
        color: #ABABAB;
      }
    }

  }

  .custom-ul {
    max-width: 400px;
    list-style-type: none;
    // counter-reset: steps;
    margin: 0;
    font-family: sans-serif;
    padding-left: 0px;
  }
  .custom-ul li {
    padding: 0 0 10px 50px;
    position: relative;
    margin: 0;
    vertical-align: middle;
    font-size: 12px;
    line-height: 24px;
  }
  .custom-ul li:after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    // counter-increment: steps;
    border: 3px solid #7BAA64;
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    background: #fff;
  }
  .custom-ul li:before {
    position: absolute;
    left: 13px;
    top: 0;
    content: "";
    height: 100%;
    width: 0;
    border-left: 1px solid #7BAA64;
  }
  .custom-ul li:last-of-type:before {
    border: none;
  }
  
.sticker-tint {
    border-radius: 5px;;
    // outline: 11px solid rgba(255, 0, 0, 0.5) !important;
    outline-offset: -11px;
    overflow: hidden;
    position: relative;
    height: 20px;
    width: 11px;
}
.disable-text-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filter-dropdowns{
  background: #E6E6E6;
  border-radius: 10px;
}
.text-white-hover:hover{
  color: white !important;
}
</style>